import * as c from 'common/c'
import { get, findIndex } from 'lodash-es'

const DEFAULT_CENTER = [22.27825982386202, 37.343854737018034]
const DEFAULT_ZOOM = 4

const initState = {
  editField: false,
  drawBoundary: false,
  editBoundary: false,
  drawHole: false,
  unsavedChanges: false,
  drawType: 'MultiPolygon',
  newFeature: undefined,
  center: DEFAULT_CENTER,
  zoom: DEFAULT_ZOOM,
  pointInfoReq: undefined,
  pointInfoRes: c.UNDEFINED,
  areaInfoReq: undefined,
  areaInfoRes: c.UNDEFINED,
  addNote: undefined,
  noteText: undefined,
  noteFeatures: new Map(),
  legendUrl: [],
  legendUrl_a: [],
  legendUrl_b: [],
  extent: undefined,
  wmsLayers: [],
  wmsLayersA: [],
  wmsLayersB: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case c.MAP_CENTER: {
      return update(state, { center: { $set: action.payload } })
    }

    case c.MAP_ZOOM: {
      return update(state, { zoom: { $set: action.payload } })
    }

    case c.DRAW_BOUNDARY: {
      return update(state, {
        drawBoundary: { $set: action.payload },
        editBoundary: { $set: false },
        drawHole: { $set: false },
      })
    }

    case c.DRAW_HOLE: {
      return update(state, {
        drawHole: { $set: action.payload },
        editBoundary: { $set: false },
        drawBoundary: { $set: false },
      })
    }

    case c.SET_NEW_FEATURE: {
      return update(state, { newFeature: { $set: action.payload } })
    }

    case c.SET_DRAW_TYPE: {
      return update(state, { drawType: { $set: action.payload } })
    }

    case c.SET_EDIT_FIELD: {
      return update(state, { editField: { $set: action.payload } })
    }

    case c.EDIT_BOUNDARY: {
      return update(state, {
        editBoundary: { $set: action.payload },
        drawBoundary: { $set: false },
        drawHole: { $set: false },
      })
    }

    case c.BOUNDARY_CHANGES_MADE: {
      return update(state, {
        unsavedBoundary: { $set: action.payload },
      })
    }

    case c.POINT_INFO_REQ: {
      return update(state, { pointInfoReq: { $set: action.payload } })
    }

    case c.POINT_INFO_RES: {
      return update(state, { pointInfoRes: { $set: action.payload } })
    }

    case c.POINT_INFO_CLR: {
      return update(state, {
        pointInfoReq: { $set: undefined },
        pointInfoRes: { $set: c.UNDEFINED },
      })
    }

    case c.AREA_INFO_REQ: {
      return update(state, { areaInfoReq: { $set: action.payload } })
    }

    case c.AREA_INFO_RES: {
      return update(state, { areaInfoRes: { $set: action.payload } })
    }

    case c.AREA_INFO_CLR: {
      return update(state, {
        areaInfoReq: { $set: undefined },
        areaInfoRes: { $set: c.UNDEFINED },
      })
    }

    case c.SET_LAYER_LEGEND: {
      const index = findIndex(
        get(state, 'legendUrl', []),
        (layer) =>
          layer.name === action.payload.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (index > -1) {
        return update(state, {
          legendUrl: { $splice: [[index, 1, action.payload]] },
        })
      } else return update(state, { legendUrl: { $unshift: [action.payload] } })
    }

    case c.REMOVE_LAYER_LEGEND: {
      const idx = findIndex(
        get(state, 'legendUrl', []),
        (layer) =>
          layer.name === action?.payload?.name &&
          layer.harvest === action?.payload?.harvest,
      )

      if (idx > -1) {
        return update(state, { legendUrl: { $splice: [[idx, 1]] } })
      } else if (action.payload === undefined) {
        return update(state, { legendUrl: { $set: [] } })
      } else
        return update(state, {
          legendUrl: { $set: get(state, 'legendUrl', []) },
        })
    }

    case c.REMOVE_LAYER_LEGEND_A: {
      const idx = findIndex(
        get(state, 'legendUrl_a', []),
        (layer) =>
          layer.name === action?.payload?.name &&
          layer.harvest === action?.payload?.harvest,
      )

      if (idx > -1) {
        return update(state, { legendUrl_a: { $splice: [[idx, 1]] } })
      } else if (action.payload === undefined) {
        return update(state, { legendUrl_a: { $set: [] } })
      } else
        return update(state, {
          legendUrl_a: { $set: get(state, 'legendUrl', []) },
        })
    }

    case c.REMOVE_LAYER_LEGEND_B: {
      const idx = findIndex(
        get(state, 'legendUrl_b', []),
        (layer) =>
          layer.name === action?.payload?.name &&
          layer.harvest === action?.payload?.harvest,
      )

      if (idx > -1) {
        return update(state, { legendUrl_b: { $splice: [[idx, 1]] } })
      } else if (action.payload === undefined) {
        return update(state, { legendUrl_b: { $set: [] } })
      } else
        return update(state, {
          legendUrl_b: { $set: get(state, 'legendUrl_b', []) },
        })
    }

    case c.REMOVE_LAYER_LEGENDS: {
      return update(state, {
        legendUrl: { $set: [] },
        legendUrl_a: { $set: [] },
        legendUrl_b: { $set: [] },
      })
    }

    case c.REMOVE_LAYER_LEGENDS_A: {
      return update(state, {
        legendUrl_a: { $set: [] },
      })
    }

    case c.REMOVE_LAYER_LEGENDS_B: {
      return update(state, {
        legendUrl_b: { $set: [] },
      })
    }

    case c.SET_LAYER_A: {
      const index = findIndex(
        get(state, 'wmsLayersA', []),
        (layer) =>
          layer.name === action.payload.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (index > -1) {
        return update(state, {
          wmsLayersA: { $splice: [[index, 1, action.payload]] },
        })
      } else
        return update(state, { wmsLayersA: { $unshift: [action.payload] } })
    }

    case c.SET_LAYER_B: {
      const index = findIndex(
        get(state, 'wmsLayersB', []),
        (layer) =>
          layer.name === action.payload.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (index > -1) {
        return update(state, {
          wmsLayersB: { $splice: [[index, 1, action.payload]] },
        })
      } else
        return update(state, { wmsLayersB: { $unshift: [action.payload] } })
    }

    case c.SET_LAYER: {
      const index = findIndex(
        get(state, 'wmsLayers', []),
        (layer) =>
          layer.name === action.payload.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (index > -1) {
        return update(state, {
          wmsLayers: { $splice: [[index, 1, action.payload]] },
        })
      } else return update(state, { wmsLayers: { $unshift: [action.payload] } })
    }

    case c.REMOVE_LAYER: {
      const idx = findIndex(
        get(state, 'wmsLayers', []),
        (layer) =>
          layer.name === action?.payload?.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (idx > -1) {
        return update(state, { wmsLayers: { $splice: [[idx, 1]] } })
      } else if (action.payload === undefined) {
        return update(state, { wmsLayers: { $set: [] } })
      } else
        return update(state, {
          wmsLayers: { $set: get(state, 'wmsLayers', []) },
        })
    }

    case c.REMOVE_LAYER_A: {
      const idx = findIndex(
        get(state, 'wmsLayersA', []),
        (layer) =>
          layer.name === action?.payload?.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (idx > -1) {
        return update(state, { wmsLayersA: { $splice: [[idx, 1]] } })
      } else if (action.payload === undefined) {
        return update(state, { wmsLayersA: { $set: [] } })
      } else
        return update(state, {
          wmsLayersA: { $set: get(state, 'wmsLayersA', []) },
        })
    }

    case c.REMOVE_LAYER_B: {
      const idx = findIndex(
        get(state, 'wmsLayersB', []),
        (layer) =>
          layer.name === action?.payload?.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (idx > -1) {
        return update(state, { wmsLayersB: { $splice: [[idx, 1]] } })
      } else if (action.payload === undefined) {
        return update(state, { wmsLayersB: { $set: [] } })
      } else
        return update(state, {
          wmsLayersB: { $set: get(state, 'wmsLayersB', []) },
        })
    }

    case c.RESET_LAYERS: {
      return update(state, { wmsLayers: { $set: [] }, legendUrl: { $set: [] } })
    }

    case c.RESET_LAYERS_A: {
      return update(state, {
        wmsLayersA: { $set: [] },
        legendUrl_a: { $set: [] },
      })
    }

    case c.RESET_LAYERS_B: {
      return update(state, {
        wmsLayersB: { $set: [] },
        legendUrl_b: { $set: [] },
      })
    }

    case c.SET_LAYER_LEGEND_A: {
      const index = findIndex(
        get(state, 'legendUrl_a', []),
        (layer) =>
          layer.name === action.payload.name &&
          layer?.harvest === action?.payload?.harvest,
      )
      if (index > -1) {
        return update(state, {
          legendUrl_a: { $splice: [[index, 1, action.payload]] },
        })
      } else
        return update(state, { legendUrl_a: { $unshift: [action.payload] } })
    }

    case c.SET_LAYER_LEGEND_B: {
      const index = findIndex(
        get(state, 'legendUrl_b', []),
        (layer) =>
          layer.name === action.payload.name &&
          layer?.harvest === action?.payload?.harvest,
      )
      if (index > -1) {
        return update(state, {
          legendUrl_b: { $splice: [[index, 1, action.payload]] },
        })
      } else
        return update(state, { legendUrl_b: { $unshift: [action.payload] } })
    }

    case c.SET_EXTENT: {
      return update(state, { extent: { $set: action.payload } })
    }

    default:
      return state
  }
}
