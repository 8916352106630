import * as c from 'common/c'
import { get, findIndex } from 'lodash-es'
import update from 'immutability-helper'

declare module 'state/notification' {
  export type Notification = {
    id: number
    tenantId: string
    userId: string
    message: string
    expiresAt: string
    category: string
    severity: string
  }
}

const initialState = {
  collection: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.NOTIFICATIONS_FETCHED: {
      return update(state, { collection: { $set: action.payload } })
    }

    default:
      return state
  }
}
