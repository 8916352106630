import { shouldShowFarmSetupWizard } from 'actions/app'
import { fetchCropsWithCropVariety, fetchDefaultCrops } from 'api/crop'
import { getEquipments } from 'api/equipment'
import { fetchBrands, getFarm } from 'api/farm'
import { fetchFields } from 'api/field'
import { getHarvestYears } from 'api/harvest'
import { fetchHardware } from 'api/hardware'
import { getHeaders } from 'api/headers'
import { fetchOptions, fetchTenant } from 'api/tenant'
import { fetchUserAccount } from 'api/user'
import { fetchAvailableIntegrations } from 'api/integration'
import http from 'common/http'
import {
  getCurrentSubscription,
  getSubscriptionDetails,
} from 'api/subscriptions'
import { getClients } from 'api/client'
import { fetchNotifications } from 'api/notification'

import { useAuth0 } from '@auth0/auth0-react'

export default function HttpHandler(props) {
  const { store, baseURL } = props

  const token = useSelector((state) => state.account.token)

  const auth0 = useAuth0()

  useEffect(() => {
    http.init({ baseURL, auth0 })
  }, [])

  useEffect(() => {
    if (token) {
      http.withTokens(token)

      store.dispatch(fetchOptions())
      store.dispatch(fetchUserAccount())
      store.dispatch(fetchTenant()).then(() => {
        if (
          localStorage.getItem('wizardActiveStep') &&
          localStorage.getItem('wizardActiveStep') != 'null'
        ) {
          store.dispatch(shouldShowFarmSetupWizard())
        }
      })

      store.dispatch(getHarvestYears())
      store.dispatch(getCurrentSubscription())
      store.dispatch(getSubscriptionDetails())
      store.dispatch(getFarm())
      store.dispatch(getClients())
      store.dispatch(fetchFields())
      store.dispatch(fetchDefaultCrops())
      store.dispatch(fetchAvailableIntegrations())
      store.dispatch(fetchCropsWithCropVariety())
      store.dispatch(fetchBrands())
      store.dispatch(getHeaders())
      store.dispatch(getEquipments())
      store.dispatch(fetchHardware())
      store.dispatch(fetchNotifications())
    }
  }, [token])

  return null
}

HttpHandler.propTypes = {
  store: PropTypes.object.isRequired,
  baseURL: PropTypes.string,
}
