import { Image as ImageLayer } from 'ol/layer'
import ImageWMS from 'ol/source/ImageWMS'
import useMap from 'hooks/use-map'
import { get } from 'lodash-es'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { transformExtent } from 'ol/proj'
import { buffer } from 'ol/extent'

export default function MapStaticBoundaryLayer(props) {
  const { fieldLayerName = '', mapRef = 'map', tenantId, layerZIndex } = props

  const [params, setParams] = useState({
    tiled: true,
    SRS: 'EPSG:4326',
    viewparams: '',
  })

  let map = useMap(mapRef)

  const [source, setSource] = useState()
  const [layer, setLayer] = useState()
  const client = useSelector((state) => get(state, 'client.singleton'))
  const selectedYear = useSelector((state) => get(state, 'field.year'))
  const mapExtent = useSelector((state) => get(state, 'map.extent'))

  useEffect(() => {
    setParams({
      tiled: false,
      SRS: 'EPSG:4326',
      layers: fieldLayerName,
      viewparams: `tenant_id:${tenantId};year:${selectedYear ?? ''};client_id:${
        client?.id ?? ''
      }`,
    })
  }, [tenantId, client, selectedYear])

  useEffect(() => {
    const _source = new ImageWMS({
      url: `${GEOSERVER_URL}/ows`,
      serverType: 'geoserver',
      params: params,
      transition: 0,
    })
    setSource(_source)
  }, [params])

  useEffect(() => {
    if (map && source && mapExtent) {
      // Buffer the extent by 20%
      const bufferSize = Math.min(
        (mapExtent[2] - mapExtent[0]) * 0.2,
        (mapExtent[3] - mapExtent[1]) * 0.2,
      )
      const bufferedExtent = buffer(mapExtent, bufferSize)

      const _layer = new ImageLayer({
        source,
        zIndex: layerZIndex,
      })
      // This extent is the default extent of the customer's account, buffered 20%
      // to prevent clipping of the clustered version of the field boundaries.
      // By setting this extent, the Openlayer map will not render tiles
      // outside this extent.
      _layer.setExtent(
        transformExtent(bufferedExtent, 'EPSG:4326', 'EPSG:3857'),
      )

      setLayer(_layer)
    }
  }, [map, source, mapExtent])

  useEffect(() => {
    if (layer) {
      map.addLayer(layer)
    }

    return () => {
      map.removeLayer(layer)
    }
  }, [layer])

  return null
}
