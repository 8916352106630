import http from 'common/http'
import { notificationsFetched } from 'actions/notification'

export const fetchNotifications = () => {
  return (dispatch) => {
    return http.get(`/notification`).then((res) => {
      dispatch(notificationsFetched(res.data))
    })
  }
}
