import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { closeModal } from 'actions/app'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import ModalContainer from 'components/modal/modal-container'
import ModalActions from 'components/modal/modal-actions'
import ModalHeader from 'components/modal/modal-header'
import { FieldModel } from 'state/field'
import { AppState } from 'state'
import { HarvestModel } from 'state/harvest'
import { switchBoundariesForEdit } from 'api/field'
import { Typography } from '@mui/material'
export type EditPreviousYearProps = {
  editCallBack: Function
  year: number
  currentYear: number
  field: FieldModel
  isOpen: boolean
}

type FieldBoundaryOption = {
  name: string
  harvestYear: number | null
  label: string
}

export default function EditPreviousYear(props) {
  const { t } = useTranslation()
  const DEFAULT_OPTION = {
    name: 'edit_future_version',
    harvestYear: null,
    harvestId: null,
    label: t('edit_future_version'),
  }
  const dispatch = useDispatch()
  const availableHarvests: HarvestModel[] = useSelector(
    (state: AppState) => state.harvest.collection,
  )
  const selectedYear = useSelector((state: AppState) => state.field.year)
  const selectedClient = useSelector(
    (state: AppState) => state.client.singleton,
  )
  const [boundaryOptions, setBoundaryOptions] = useState<FieldBoundaryOption[]>(
    [],
  )
  const [selectedBoundary, setSelectedBoundary] =
    useState<FieldBoundaryOption>(DEFAULT_OPTION)

  useEffect(() => {
    let _boundaryOptions: FieldBoundaryOption[] = [DEFAULT_OPTION]
    if (selectedYear) {
      _boundaryOptions.push({
        harvestYear: selectedYear,
        label: `${selectedYear}`,
        name: `${selectedYear}`,
      })
    }
    setBoundaryOptions(_boundaryOptions)
  }, [availableHarvests, selectedYear])

  const handleSelectedYearPress = useCallback(() => {
    if (typeof props.editCallBack === 'function') {
      if (selectedBoundary.harvestYear === null) {
        dispatch(
          switchBoundariesForEdit({
            clientId: selectedClient?.id,
            field: props.field,
            isOpen: props.isOpen,
            callBack: props.editCallBack,
          }),
        )
      } else {
        props.editCallBack(props.field, props.isOpen)
        dispatch(closeModal())
      }
    }
  }, [selectedBoundary, selectedClient])

  const handleCancelPress = useCallback(() => {
    dispatch(closeModal())
  }, [])

  const handleBoundarySelectionChange = useCallback((e, newValue) => {
    setSelectedBoundary(newValue)
  }, [])

  return (
    <ModalContainer>
      <ModalHeader>{t('edit_field_header')}</ModalHeader>
      <Box
        sx={{ my: '10px' }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '75px',
        }}
      >
        {t('edit_most_recent_harvest', { field_name: props.field.name })}
        <Autocomplete
          disableClearable
          fullWidth
          style={{ maxHeight: '40px' }}
          sx={{
            '& input': {
              height: '1em',
            },
            '& label': {
              lineHeight: '1.5em',
              fontSize: '0.75em',
            },
          }}
          id="clients"
          autoHighlight
          isOptionEqualToValue={(option, value) => option.name === value.name}
          size="small"
          options={boundaryOptions}
          selectOnFocus
          getOptionLabel={(option) => option?.label || ''}
          value={selectedBoundary}
          onChange={(event, newValue) => {
            handleBoundarySelectionChange(event, newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('boundary_version')}
              style={{ width: '100%' }}
            />
          )}
        />
      </Box>

      <ModalActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSelectedYearPress}
        >
          {t('edit')}
        </Button>
        <Button variant="contained" color="inherit" onClick={handleCancelPress}>
          {t('cancel')}
        </Button>
      </ModalActions>
    </ModalContainer>
  )
}
