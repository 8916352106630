import styled from 'styled-components'
import { get } from 'lodash-es'
import { resetLayers, setDefaultExtent } from 'actions/field'
import { removeLayerLegends } from 'actions/map'
import { FormControl, MenuItem, Select } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getTranslatedName, getYearsWithHarvests } from 'common/misc'
import { useEffect } from 'react'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import Checkbox from '@mui/material/Checkbox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { fetchBBox } from 'api/field'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const useStyles = makeStyles({
  selectField: {
    fontSize: '0.75em',
    height: '28px',
  },
})

export default function CropYearPick(props) {
  const classes = useStyles()

  const {
    crops = [],
    onSeasonChange,
    assignSelectedClient,
    assignSelectedFarms,
    harvestYears,
    defaultCrop,
    selectedFarms,
    selectedClient,
  } = props
  const clients = useSelector((state) => get(state, 'client.collection' || []))
  const clientsWithBoundaries = useSelector(
    (state) => state.client.clientFieldFeaturesFetched,
  )

  const [selectedCrop, setSelectedCrop] = useState(defaultCrop)

  const cffEnabled = useSelector(
    (state) => state.account.singleton.prefs?.cff?.enabled ?? false,
  )

  /*Check from the product team if farm default also needs to be set from preferences. 
  TBD - if the farms autocomplete have to be the values for the selected client.
  */

  // const prefFarmDefault = useSelector((state) =>
  //   get(state, 'account.singleton.prefs.cffDefault?.["cff_farm"]'),
  // )

  const [filteredFarms, setFilteredFarms] = useState(
    selectedClient?.farms ?? [],
  )

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const filter = createFilterOptions()

  const allSelected = filteredFarms.length === selectedFarms.length

  useEffect(() => {
    if (selectedClient) {
      setFilteredFarms(
        selectedClient?.farms?.length ? selectedClient?.farms : [],
      )
    }
  }, [selectedClient])

  const yearsWithHarvests = useMemo(
    () => harvestYears.length && getYearsWithHarvests(harvestYears),
    [harvestYears],
  )
  const year = useSelector((state) => state.field.year)

  const onCropChange = useCallback(
    (e) => {
      setSelectedCrop(e.target.value)
      onSeasonChange(year + '_' + e.target.value)
    },
    [year],
  )

  const onClientChange = useCallback(
    (e, newValue, reason) => {
      let client, farms
      if (reason === 'clear') {
        client = ''
        farms = []
      } else {
        let clientId = newValue
        if (typeof newValue !== 'number') {
          clientId = newValue.id
        }
        dispatch(fetchBBox({ clientId, year }))
        if (clientsWithBoundaries[clientId] !== undefined) {
          dispatch(setDefaultExtent(clientsWithBoundaries[clientId]))
        }
        client = newValue
        farms = newValue?.['farms'] || []
      }
      localStorage.setItem('selectedClient', JSON.stringify(client))
      assignSelectedClient(client)
      localStorage.setItem('selectedFarms', JSON.stringify(farms))
      setFilteredFarms(farms)
      assignSelectedFarms(farms)
    },
    [selectedClient, clientsWithBoundaries, year],
  )

  const onFarmChange = useCallback(
    (e, newValue, reason) => {
      let farms = []
      if (reason === 'selectOption' || reason === 'removeOption') {
        if (newValue.find((option) => option.id === 0)) {
          handleToggleSelectAll()
          farms = !allSelected ? filteredFarms : []
        } else {
          farms = newValue?.length ? newValue : []
          assignSelectedFarms(newValue)
        }
      } else if (reason === 'clear') {
        handleClearOptions()
        farms = []
      }
      localStorage.setItem('selectedFarms', JSON.stringify(farms))
    },
    [selectedFarms],
  )

  //Select All
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      assignSelectedFarms(filteredFarms)
    } else {
      handleClearOptions()
    }
  }

  const handleClearOptions = () => {
    assignSelectedFarms([])
  }

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected)
  }
  //End

  const [selectedYear, setSelectedYear] = useState('')

  useEffect(() => {
    if (year) {
      setSelectedYear(year)
    }
  }, [year])

  useEffect(() => {
    //This ensures that the useEffect doesn't get into a race condition
    let active = true
    if (active) {
      onSeasonChange(selectedYear + '_' + selectedCrop)
      dispatch(removeLayerLegends())
      dispatch(resetLayers())
    }
    return () => {
      active = false
    }
  }, [selectedYear])

  useEffect(() => {
    if (yearsWithHarvests?.length) {
      setSelectedYear(yearsWithHarvests[0])
      dispatch(
        fetchBBox({ clientId: selectedClient?.id, year: yearsWithHarvests[0] }),
      )
    }
  }, [yearsWithHarvests, selectedClient])

  const onYearChange = useCallback(
    (e) => {
      dispatch(
        fetchBBox({ clientId: selectedClient?.id, year: e.target.value }),
      )
      setSelectedYear(e.target.value)
    },
    [selectedCrop, selectedClient],
  )
  return (
    <>
      <StyledRoot className="is_visible">
        <FormControl size="small" style={{ flex: '0.45' }}>
          <Select
            className={classes.selectField}
            value={selectedYear}
            onChange={onYearChange}
          >
            {!harvestYears?.length && (
              <MenuItem key={selectedYear} value={selectedYear}>
                {selectedYear}
              </MenuItem>
            )}
            {harvestYears?.length &&
              harvestYears.map((v) => {
                return (
                  <MenuItem key={v.harvestYr} value={v.harvestYr}>
                    {v.harvestYr}
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>

        <FormControl size="small" style={{ flex: '0.5' }}>
          <Select
            className={classes.selectField}
            value={selectedCrop}
            onChange={onCropChange}
          >
            <MenuItem value="all">{t('all_crops')}</MenuItem>
            {crops
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((v) => (
                <MenuItem key={v.id} value={v.id}>
                  {getTranslatedName(v.name)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </StyledRoot>

      {clients && clients.length && cffEnabled ? (
        <>
          <StyledRoot className="is_visible">
            <Autocomplete
              disableClearable
              fullWidth
              style={{ maxHeight: '40px' }}
              sx={{
                '& input': {
                  height: '1em',
                },
                '& label': {
                  lineHeight: '1.5em',
                  fontSize: '0.75em',
                },
              }}
              id="clients"
              autoHighlight
              isOptionEqualToValue={(option, value) => option.id === value.id}
              size="small"
              options={clients}
              selectOnFocus
              getOptionLabel={(option) => option?.name || ''}
              value={selectedClient ?? null}
              onChange={(event, newValue, reason) => {
                onClientChange(event, newValue, reason)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('clients')}
                  placeholder={t('clients')}
                  style={{ width: '100%' }}
                />
              )}
            />
          </StyledRoot>
          <StyledRoot className="is_visible">
            <Autocomplete
              fullWidth
              style={{ maxHeight: '30px' }}
              sx={{
                '& input': {
                  height: '1em',
                },
                '& label': {
                  lineHeight: '1.5em',
                  fontSize: '0.75em',
                },
              }}
              multiple
              limitTags={2}
              id="farms"
              size="small"
              options={filteredFarms}
              getOptionLabel={(option) => option?.name || ''}
              value={selectedFarms}
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newValue, reason) => {
                onFarmChange(event, newValue, reason)
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                return [{ name: t('select_all'), id: 0 }, ...filtered]
              }}
              renderOption={(props, option, { selected }) => {
                const selectAllProps =
                  option.id === 0 // To control the state of 'select-all' checkbox
                    ? { checked: allSelected }
                    : {}
                return (
                  <li
                    style={{ padding: '0 0 0 5px' }}
                    {...props}
                    key={option.id}
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      {...selectAllProps}
                    />
                    {option.name}
                  </li>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('farms')}
                  style={{ width: '100%' }}
                />
              )}
              renderTags={(value) => {
                const numTags = value.length
                return (
                  <div
                    data-variant="body2"
                    title={`${value[0].name} +${numTags - 1} more`}
                    style={{
                      whiteSpace: 'pre',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {value
                      .slice(0, 1)
                      .map((option) => option.name)
                      .join(', ')}

                    {numTags > 1 && ` +${numTags - 1} more`}
                  </div>
                )
              }}
            />
          </StyledRoot>
        </>
      ) : null}
    </>
  )
}

CropYearPick.propTypes = { onSeasonChange: PropTypes.func.isRequired }

const StyledRoot = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`
