import { default as OLMap } from 'ol/Map'
import View from 'ol/View'
import { DragPan, MouseWheelZoom, Select } from 'ol/interaction'
import { fromLonLat } from 'ol/proj'
import MapContext from 'context/map-context'
import LazyRender from 'components/lazy-render'
import { isFunction, isNil } from 'lodash-es'
import * as c from 'common/c'
import { ZoomSlider, Zoom } from 'ol/control'

import makeStyles from '@mui/styles/makeStyles'
import { selectStyle } from 'common/ol/styles'

const useStyles = makeStyles(() => ({
  '@global': {
    '.ol-zoom': {
      bottom: '3em !important',
      top: 'auto',
    },
    '.ol-zoom .ol-zoom-out': {
      'margin-top': '200px',
      '@media screen and  (max-height: 1000px)': { 'margin-top': 0 },
    },
    '.ol-zoomslider': {
      'background-color': 'transparent',
      bottom: 'calc(3em + 1px + 1.14 * 1.375em)',
      top: 'auto',
      '@media screen and  (max-height: 1000px)': { display: 'none' },
      //For tablets
      '@media only screen and (min-width: 768px) and (max-width: 1280px)': {
        bottom: 'calc(3.6em + 2px + 1.14 * 1.375em)',
      },
    },
    '.ol-touch .ol-zoom .ol-zoom-out': {
      'margin- top': '212px',
      '@media screen and  (max-height: 1000px)': { 'margin-top': 0 },
    },
    '.ol-touch .ol-zoomslider': {
      bottom: 'calc(3em + 1px + 1.14 * 1.375em)',
      top: 'auto',
      '@media screen and  (max-height: 1000px)': { display: 'none' },
      //For tablets
      '@media only screen and (min-width: 768px) and (max-width: 1280px)': {
        bottom: 'calc(3.6em + 2px + 1.14 * 1.375em)',
      },
    },
  },
}))

const MapProvider = (props) => {
  const { children, onSelect, selectActive = false, editing = false } = props
  const classes = useStyles()
  const [map, setMap] = useState()
  const [select, setSelect] = useState()

  useEffect(() => {
    const view = new View({
      zoom: c.DEFAULT_ZOOM,
      center: fromLonLat(c.DEFAULT_CENTER),
      enableRotation: false,
      projection: 'EPSG:3857',
      maxZoom: 21,
    })

    // default controls
    const zoomControl = new Zoom()
    const controls = [zoomControl]
    if (window.screen.height >= c.MIN_SCREEN_HEIGHT) {
      const zoomSlider = new ZoomSlider()
      controls.push(zoomSlider)
    }

    // default interactions
    const interactions = [new DragPan(), new MouseWheelZoom()]

    setSelect(
      new Select({
        style: selectStyle,
      }),
    )

    // default layers
    const layers = []

    const mapProps = {
      view,
      layers,
      controls,
      overlays: [],
      interactions,
      pixelRatio: 1,
    }
    const mapInstance = new OLMap(mapProps)

    setMap(mapInstance)

    return function cleanup() {
      mapInstance.setTarget(undefined)
    }
  }, [])

  useEffect(() => {
    function _onSelect(e) {
      onSelect(e.selected, e.unselected)
    }

    if (isFunction(onSelect) && select && map) {
      select.on('select', _onSelect)
      map.addInteraction(select)
    }

    return () => {
      if (isFunction(onSelect) && select && map) {
        select.un('select', _onSelect)
        map.removeInteraction(select)
      }
    }
  }, [map, select, onSelect])

  useEffect(() => {
    if (select) {
      if (selectActive) {
        select.setActive(!editing)
      } else {
        select.setActive(false)
      }
    }
  }, [editing, select, selectActive])

  const isDone = useMemo(() => {
    return !isNil(map)
  }, [map])

  return (
    <LazyRender
      done={isDone}
      className={classes['.ol-zoomslider']}
      render={() => {
        return <MapContext.Provider value={map}>{children}</MapContext.Provider>
      }}
    />
  )
}

MapProvider.displayName = 'MapProvider'

MapProvider.propTypes = {
  children: PropTypes.node,
  onSelect: PropTypes.func,
  selectActive: PropTypes.bool,
}

export default MapProvider
